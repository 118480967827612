import { Client } from '@/services';
import { User } from '@/models/orm/Hierarchy';
import { AUTH_REQUEST } from '@/store/actions/auth';
import { Services } from '@/store/actions/api';
import { AlertType, ErrorType, ErrorMessage } from '@/constants';
import { FormRules } from '@/utils/form';
import { jsonToFormData } from '@/utils/http-common';
import { captureException } from '@sentry/vue';

const TimeoutingComponent = () => import('@/components/ui/TimeoutingComponent.vue');

export default {
  name: 'UserPasswd',

  props: {
    user: {
      type: User,
    },
  },

  components: {
    TimeoutingComponent,
  },

  data() {
    return {
      currentPasswd: null,
      showCurrentPasswd: false,
      currentPasswdErrors: [],
      currentPasswdRules: [
        FormRules.required
      ],

      newPasswd: null,
      showNewPasswd: false,
      newPasswdErrors: [],

      confirmPasswd: null,
      showConfirmPasswd: false,
      confirmPasswdErrors: [],

      valid: true,
      sending: false,
      messageText: null,
      messageType: null,
    };
  },

  computed: {
    newPasswdRules() {
      return [
        FormRules.required,
        (v) => (
          (typeof v === 'string' && v.length >= 6)
          || this.$vuetify.lang.t('$vuetify.alerts.password_minlength')
        ),
        (v) => {
          return (this.newPasswd !== this.currentPasswd)
          || this.$vuetify.lang.t('New password can not be the same as current one.');
        },
      ];
    },

    confirmPasswdRules() {
      return [
        (v) => {
          return (this.newPasswd === this.confirmPasswd)
          || this.$vuetify.lang.t('$vuetify.alerts.confirmPassword_notMatch');
        },
      ];
    },
  },


  methods: {
    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.currentPasswd = null;
      this.newPasswd = null;
      this.confirmPasswd = null;
      this.$refs.form.reset();
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.messageText = null;
        this.messageType = null;

        this.sending = true;
        const updateProfileResult = await this.changePassword();

        if (updateProfileResult.type === AlertType.success) {
          try {
            let payload = {
              login: this.user.Email,
              password: this.newPasswd,
              remember: true,
            };

            const result = await this.$store.dispatch(AUTH_REQUEST, payload);

            if (result.data) {
              this.reset();
              this.messageText = this.$vuetify.lang.t('$vuetify.userProfile.passwordSuccess');
              this.messageType = updateProfileResult.type;
            }
          } catch (error) {
            captureException(error);
            this.messageText = this.$vuetify.lang.t('Something went wrong, please reload.');
            this.messageType = AlertType.error;
          }
        }
        this.sending = false;
      }
    },

    errorHandler(error) {
      if (error) {
        const { config, request, response, isAxiosError } = error;

        if (isAxiosError) {
          if (typeof response !== 'undefined') {
            let { data } = response;
            let { type, message } = data;

            if ([ErrorType.WS_ERROR, ErrorType.SERVICE_ERROR].includes(type) && message) {
              const regex = /(?<message>[\w]+)([:#\s]+(?<code>\w)+)?/gm;
              const match = regex.exec(message);

              if (match.length > 1 && match.groups.message) {

                if (match.groups.message === ErrorMessage.INVALID_ARGUMENTS) {
                  this.messageText = this.$vuetify.lang.t('$vuetify.alerts.invalid_password');
                  this.messageType = AlertType.error;

                  return false;
                }
              }
            }
          }
        }
      }
      return 'ChangePassword Error';
    },

    async changePassword() {
      try {
        const params = {
          service: Services.FlightScopeUser.key,
          method: Services.FlightScopeUser.methods.ChangePassword.key,
        };

        let payload = Services.FlightScopeUser.methods.ChangePassword.params;

        payload.Password = this.currentPasswd;
        payload.NewPassword = this.newPasswd;
        payload.InvalidateToken = true;

        const formData = jsonToFormData(payload);

        // eslint-disable-next-line no-await-in-loop
        const { data } = await Client({ params, data: formData, method: 'post', errorHandler: this.errorHandler, });

        if (data) {
          return { type: AlertType.success };
        }

        return { type: AlertType.success };
      } catch (error) {
        this.$log.debug(error);
        captureException(error);

        return { type: AlertType.error, message: '' };
      }
    },
  },
};
